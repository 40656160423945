<template>
  <div>
    <div class="modal-dialog modal-lg text-left">
      <div class="modal-content">
        <div class="modal-header">
          <h3
            class="modal-title cl-primary f-bold text-center"
            id="exampleModalLabel"
          >
            Delivery Proof
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitProof">
            <div class="row">
              <div class="col-md-12">
                <label for="recipient name" class="text-secondary">
                  Recipient Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="recipient_name"
                  aria-describedby="recipient_name"
                  placeholder="Enter Recipient Name"
                  v-model="form.recipient_name"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleFormControlFile1" class="text-secondary"
                    >Image 1</label
                  >
                  <input
                    type="file"
                    class="form-control-file"
                    id="exampleFormControlFile1"
                    ref="file"
                    @change="handleImageProof()"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleFormControlFile2" class="text-secondary"
                    >Image 2</label
                  >
                  <input
                    type="file"
                    class="form-control-file"
                    id="exampleFormControlFile2"
                    ref="file2"
                    @change="handleImageProof2()"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary px-5"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary px-sm-5"
            data-dismiss="modal"
            v-on:click="submitProof()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
export default {
  props: [
    "listDetailPackage",
    "customer_id",
    "readNotification",
    "notif_id",
    "getNotification",
    "getDetailPackage",
  ],
  data() {
    return {
      form: {
        package_id: this.listDetailPackage.id,
        image_1: "",
        image_2: "",
        recipient_name: "",
      },
    };
  },
  mounted() {
    console.log(this.listDetailPackage.id);
    console.log(this.customer_id);
  },

  methods: {
    submitProof() {
      Vue.swal({
        title: "Are you sure?",
        text: "Your proof of delivery will be stored in our system",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          const base_url = "https://api-dev.mimologistics.id/";
          // const base_url = "http://localhost:8000/";
          let formData = new FormData();
          this.loading = true;
          formData.append("package_id", this.listDetailPackage.id);
          formData.append("recipient_name", this.form.recipient_name);
          formData.append("image_1", this.form.image_1);
          formData.append("image_2", this.form.image_2);
          axios({
            url: base_url + "delivery-proof",
            data: formData,
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((res) => {
            console.log(res);
            Vue.swal("Success!", "Delivery Proof Submitted", "success").then(
              () => {
                this.getDetailPackage();
              }
            );
          });
        }
      });
      console.log(this.package_id);
      console.log(this.customer_id);
      console.log(this.form);
    },

    handleImageProof() {
      this.form.image_1 = this.$refs.file.files[0];
      console.log(this.form.image_1);
    },
    handleImageProof2() {
      this.form.image_2 = this.$refs.file2.files[0];
      console.log(this.form.image_2);
    },
  },
};
</script>
