<template>
  <div>
    <div class="row text-left px-5 mt-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Packing list File (PDF)</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="package_detail">
        <a :href="package_detail.package_list" target="_blank">View</a>
      </div>
    </div>
    <hr />

    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Invoices user file (PDF)</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="package_detail">
        <a :href="package_detail.invoice" target="_blank">View</a>
      </div>
    </div>
    <hr />

    <div v-if="package_invoice">
      <div v-if="package_invoice.length !== 0">
        <div class="row text-left px-5">
          <div class="col-sm-12">
            <h6>Invoices admin</h6>
          </div>
          <div class="col-sm-12">
            <div class="w-100">
              <table class="table table-bordered w-100">
                <thead>
                  <th>Description</th>
                  <th>Carton</th>
                  <th>Cbm</th>
                </thead>
                <tr v-for="(inv, i) in package_invoice" :key="i">
                  <td>{{ inv.description }}</td>
                  <td>{{ inv.carton }}</td>
                  <td>{{ inv.cbm }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          v-if="
            listDetailPackage.status_checking == 'waiting' &&
            package_detail.tracking_number
          "
        >
          <button
            class="btn btn-primary px-4 mx-1"
            v-on:click="updateStatusChecking(listDetailPackage.id, 'match')"
          >
            Match
          </button>
          <button
            class="btn btn-danger px-4 mx-1"
            v-on:click="updateStatusChecking(listDetailPackage.id, 'not-match')"
          >
            Not Match
          </button>
        </div>
        <hr />
      </div>
    </div>

    <div v-if="package_detail.tracking_number">
      <div class="row text-left px-5">
        <div class="col-sm-3">
          <h6 class="mb-0">Tracking Number</h6>
        </div>
        <div class="col-sm-9 text-secondary">
          <p>{{ package_detail.tracking_number }}</p>
        </div>
      </div>
      <hr />
    </div>

    <div v-if="delivery_proof">
      <div v-if="delivery_proof.length != 0">
        <div class="row text-left px-5">
          <div class="col-sm-3">
            <h6 class="mb-0">Picture Delivery</h6>
          </div>
          <div v-for="(delv, i) in delivery_proof" :key="i">
            <a :href="delv.image_1" target="_blank">View File 1</a> -
            <a :href="delv.image_2" target="_blank">View File 2</a>
            <p target="_blank">Recipient Name: {{ delv.recipient_name }}</p>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        class="btn btn-primary px-5 my-4 float-right"
        data-toggle="modal"
        data-target=".bd-modal-full-payment"
        v-if="
          listDetailPackage.status_payment == 'paid' &&
          delivery_proof.length == 0
        "
      >
        Delivery Proof
      </button>
      <div
        class="modal fade bd-modal-full-payment"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <delivery-proof
          :listDetailPackage="listDetailPackage"
          :getDetailPackage="getDetailPackage"
        ></delivery-proof>
      </div>
    </div>

    <div v-if="isDeliveryProof">
      <div class="card">
        <div class="card-body card-shadow">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur
          fuga sapiente illum unde ut animi laborum hic deserunt eius recusandae
          qui, nam repudiandae architecto nobis cumque, dicta quas amet error.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeliveryProof from "./delivery-proof.vue";
export default {
  components: { DeliveryProof },
  props: [
    "package_detail",
    "package_invoice",
    "listDetailPackage",
    "delivery_proof",
    "isDeliveryProof",
    "changeStatusChecking",
    "getDetailPackage",
  ],
  name: "CustomerPackageDetailOther",
  methods: {
    updateStatusChecking(id, status) {
      this.changeStatusChecking(id, status);
    },
  },
};
</script>
